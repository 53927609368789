<template lang="pug">
TableV2(
  :headers="headers"
  :items="items.results"
  :pageCount="items.count"
  :currentPage="items.current"
  :querySearch="getStatementsPositionFromISC"
  :isLoading="isLoading"
  :saveReport="getStatementsPositionFromISCExcel"
)
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data () {
    return {
      headers: [
        { value: 'sailorFullName',
          text: this.$t('tableHeaders.sailor_full_name'),
          sortable: false },
        { value: 'sailor.id',
          text: this.$t('tableHeaders.sailor_id'),
          sortable: false },
        { value: 'created_at',
          text: this.$t('tableHeaders.created_at'),
          sortable: false },
        { value: 'crewing_company',
          text: this.$t('tableHeaders.crewingName'),
          sortable: false },
        { value: 'manager_full_name',
          text: this.$t('tableHeaders.manager_full_name'),
          sortable: false },
        { value: 'rank',
          text: this.$t('tableHeaders.rank'),
          sortable: false },
        { value: 'list_positions',
          text: this.$t('tableHeaders.position'),
          sortable: false },
        { value: 'include_sailor_passport',
          text: this.$t('tableHeaders.include_sailor_passport'),
          sortable: false },
        { value: 'status_document',
          text: this.$t('tableHeaders.status_document'),
          sortable: false }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.report.statementPositionFromISC,
      isLoading: state => state.report.isLoadingReport
    })
  },
  methods: {
    ...mapActions(['getStatementsPositionFromISC', 'getStatementsPositionFromISCExcel'])
  }
}
</script>
